* {
  box-sizing: border-box;
}

body {
  font-family: "Nunito Sans", sans-serif;
  letter-spacing: 1px;
  color: #161925;
  background: rgb(24, 99, 24, 0.1);
}

h1 {
  font-size: 3em;
}

h2 {
  font-size: 2.5em;
}

h3 {
  font-size: 2em;
}

h4 {
  font-size: 1.5em;
}

p {
  font-size: 1em;
  margin-bottom: 0;
}

.btn-yellow {
  background: #f1d302;
  padding: 20px 50px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 600;
  border: 1px solid #f1d302;
  border-radius: 3px;
  cursor: pointer;
  color: #161925;
}

.btn-yellow:hover {
  opacity: 0.8;
  cursor: pointer;
  color: #161925;
}

.btn-red-large,
.btn-red-small,
.btn-blue-large,
.btn-blue-small {
  color: #ffffff;
  font-family: inherit;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  border-radius: 3px;
}

.btn-red-large,
.btn-red-small {
  background: #c1292e;
  border: 1px solid #c1292e;
}

.btn-blue-large,
.btn-blue-small {
  background: #235789;
  border: 1px solid #235789;
}

.btn-red-large,
.btn-blue-large {
  padding: 1.2em 3em;
}

.btn-red-small,
.btn-blue-small {
  padding: 0.8em 1.8em;
}

.btn-blue-large:hover,
.btn-blue-small:hover {
  background: #466989;
}

.topnav-section {
  background: #f1d302;
  padding-top: 8px;
  padding-bottom: 8px;
}

.call-number {
  /* float: right; */
  font-weight: 700;
}

.navbar-section {
  background: rgb(24, 99, 24, 0.1);
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5em 0em;
}

.navbar-brand:hover {
  opacity: 0.8;
}

.nav-link {
  color: #161925;
  text-transform: uppercase;
  font-weight: 800;
}

.nav-link:hover {
  color: #161925;
  opacity: 0.6;
  text-decoration: underline;
}

.navbar-toggler-right {
  border: 2px solid #161925;
  padding-left: 8px;
  padding-right: 8px;
}

.navbar-toggler-right .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(22,25,37)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.hero-section {
  height: 80vh;
  background: rgb(24, 99, 24, 0.3);
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 200px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

.footer-section {
  background: #186318;
}

.footer-title,
.footer-text {
  color: #ffffff;
}

.footer-link {
  color: #f4f4f4;
}

.footer-link:hover {
  color: #ffffff;
  text-decoration: underline;
}

.copyright-text {
  text-align: center;
  color: #ffffff;
}

.video-section {
  background: #ececec;
}

.accordion-section {
  background: hsla(0, 0%, 86.7%, 0.28);
}

.title {
  cursor: pointer;
  background: #f1d302;
  padding: 15px 15px;
  border-bottom: 1px solid #ececec;
}

.content {
  overflow: hidden;
  background: rgba(0, 0, 0, 0.1);
}

.content-wrapper {
  padding: 20px 15px 20px 15px;
}

.card {
  border: 0px solid rgba(0, 0, 0, 0.125);
}

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
  border-bottom-right-radius: calc(0.25rem - 1px);
}

.safari-link {
  color: #161925;
}

.hero-image-section {
  height: 500px;
  background: #ececec;
}

.hero-image {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(../buimages/gorillas-in-rushaga-safari-uganda.png);
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.hero-image-contact-us {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(../buimages/equator-queen-elizabeth-national-park-uganda.png);
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.hero-image-about-us {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(../buimages/river-nile-exploration-uganda.png);
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.hero-text {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}

textarea.form-control {
  margin-bottom: 1.5rem;
}

.form-control {
  height: calc(1.5em + 1rem + 2px);
  border: 2px solid #ced4da;
}

.safari-map {
  border: 1px solid #f1d302;
}

.Toastify__toast-body {
  margin: auto 10px;
  text-align: center;
  flex: 1 1;
}

.Toastify__toast--default {
  background: #186318;
  color: #fff;
  border-radius: 6px;
}

.Toastify__close-button--default {
  color: #fff;
  /* opacity: 0.3; */
}

.Toastify__close-button {
  color: #fff;
  font-weight: bold;
  font-size: 15px;
  background: transparent;
  outline: none;
  border: none;
  /* padding: 0; */
  cursor: pointer;
  opacity: 0.7;
  transition: 0.3s ease;
  align-self: flex-start;
}

.Toastify__progress-bar--default {
  background: #f1d302
}

@media (max-width: 768px) {
  .social-icons {
    display: none;
  }
}
